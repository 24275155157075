var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarmCont" }, [
    _c("div", { staticClass: "listheader" }, [
      _c("div", { staticClass: "titleName" }, [_vm._v("请即清理")]),
      _c("span", [_vm._v("合计：" + _vm._s(_vm.offlineRoomList.length))]),
    ]),
    _c("div", { staticClass: "alarm_panel" }, [
      _c("div", { staticClass: "storesDetails" }, [
        _vm.offlineName
          ? _c("div", { staticClass: "storesName" }, [
              _vm._v(_vm._s(_vm.showName(_vm.offlineName))),
            ])
          : _vm._e(),
        _c("div", { staticClass: "detailsListCont" }, [
          !_vm.showDataFlag
            ? _c(
                "div",
                _vm._l(_vm.offlineRoomList, function (item, index) {
                  return _c("div", { key: index }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(item.floorName) +
                        _vm._s(item.roomNumber) +
                        "\n            房\n          "
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          !_vm.offlineRoomList || _vm.offlineRoomList.length == 0
            ? _c(
                "div",
                { staticClass: "noData2 flex flex-center align-center" },
                [_vm._v("暂无 请即清理 数据")]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }