var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c("ul", { staticClass: "flexContent flex" }, [
      _c("li", { staticClass: "leftData flex flex-column" }, [
        _c(
          "div",
          { staticClass: "takeNumber bk_b" },
          [
            _c(
              "dv-border-box-10",
              [_c("roomsTop", { attrs: { roleType: "1" } })],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sosAlarm  bk_b" },
          [
            _c(
              "dv-border-box-10",
              [_c("sos", { staticClass: "listPd", attrs: { roleType: "1" } })],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "sosAlarm bk_b" },
          [
            _c(
              "dv-border-box-10",
              [
                _c("offline", {
                  staticClass: "listPd",
                  attrs: { roleType: "1" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("li", { staticClass: "eachartsCont flex flex-column flex-1" }, [
        _c(
          "div",
          { staticClass: "mapChart" },
          [_c("mapDemo", { attrs: { showType: "1" } })],
          1
        ),
        _c(
          "div",
          { staticClass: "bar_charts" },
          [_c("barCharts", { attrs: { showType: "1" } })],
          1
        ),
      ]),
      _c("li", { staticClass: "rightData flex flex-column" }, [
        _c(
          "div",
          { staticClass: "probability  bk_b" },
          [
            _c("dv-border-box-10", [
              _c(
                "div",
                { staticClass: "demo listPd" },
                [_c("checkInTime", { attrs: { roleType: "1" } })],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "probability  bk_b" },
          [
            _c("dv-border-box-10", [
              _c(
                "div",
                { staticClass: "demo listPd" },
                [_c("clearRoom", { attrs: { roleType: "1" } })],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "probability  bk_b" },
          [
            _c("dv-border-box-10", [
              _c(
                "div",
                { staticClass: "demo listPd" },
                [_c("menci", { attrs: { roleType: "1" } })],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }