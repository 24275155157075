
<template>
  <div class="home">
    <ul class="flexContent flex">
      <li class="leftData flex flex-column">
        <!-- 取电房间数 -->
        <div class="takeNumber bk_b">
          <dv-border-box-10>
            <roomsTop roleType="1" />
          </dv-border-box-10>
        </div>
        <!-- sos报警 -->
        <div class="sosAlarm  bk_b">
           <dv-border-box-10>
            <!-- <grade class="listPd" /> -->
             <sos class="listPd" roleType="1" />
          </dv-border-box-10>
            <!-- <sos /> -->
        </div>

        <div class="sosAlarm bk_b">
          <dv-border-box-10>
            <!-- <grade class="listPd" /> -->
             <offline class="listPd" roleType="1" />
          </dv-border-box-10>
            <!-- <offline /> -->
        </div>
      </li>
      <li class="eachartsCont flex flex-column flex-1">
        <!-- <div id="myChart"></div> -->
        <div class="mapChart">
          <mapDemo  showType="1"/>
        </div>
        <div class="bar_charts">
          <barCharts showType="1" />
        </div>
      </li>
      <li class="rightData flex flex-column">
        <!-- 设备出租率 -->
        <div class="probability  bk_b">
         <dv-border-box-10>
            <div class="demo listPd">
              <checkInTime  roleType="1"/>
            </div>
          </dv-border-box-10>
      
        </div>

        <!-- 出租率情况 -->
        <div class="probability  bk_b">
          <dv-border-box-10>
            <div class="demo listPd">
              <clearRoom roleType="1" />
            </div>
          </dv-border-box-10>
          <!-- <clearRoom /> -->
        </div>
        <div class="probability  bk_b">
              <!-- <menci /> -->
          <dv-border-box-10>
            <div class="demo listPd">
              <menci roleType="1" />
            </div>
          </dv-border-box-10>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

import roomsTop from "../components/roomsTop2";
import menci from "../components/menci";
import offline from "../components/offline";
import mapDemo from "../components/chinaMap";
import barCharts from "../components/barCharts";
import checkInTime from "../components/checkInTime";
import eqAndpms from "../components/eqAndpms";
import clearRoom from "../components/clearRoom";
import sos from "../components/sos";

export default {
  // name: "Home",
  components: {
    roomsTop,
    checkInTime,
    eqAndpms,
    menci,
    offline,
    mapDemo,
    barCharts,
    clearRoom,
    sos
  },
 
  data() {
    return {
    };
  },
  mounted() {
    // window.addEventListener("resize", () => {
    //   // this.myChart.resize();
    //   // this.lineEcharts.resize();
    // });
  },
 

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
@import "@/assets/mdKanban/kanbanPage.scss";
</style>
